import request from "./http"



/**
 * @description 登录
 *  @param  {Object}}data 用户名+密码
 * @return 返回响应数据
 */
export function login(data) { //例子
    return request({
        url: '/api/admin/login',
        method: 'post',
        data
    })
}


/**
 * @description 修改用户密码
 *  @param  {Object}}data 密码类
 * @return 返回响应数据
 */
export function updatePassword(data) { //例子
    return request({
        url: `/api/admin/updatePassword`,
        method: 'post',
        data
    })
}


/**
 * @description 查询用户分页数据
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 *  @param  {Object}}data 参数
 * @return 返回响应数据
 */
export function getUserList(limit, page, data) {
    return request({
        url: `/api/admin/user/getUserList?limit=${limit}&page=${page}`,
        method: 'post',
        data: data
    })
}


/**
 * @description 新增数据
 *  @param  {Object}}entity 参数
 * @return 返回响应数据
 */
export function createUser(entity) {
    return request({
        url: `/api/admin/user/createUser`,
        method: 'post',
        data: entity
    })
}


/**
 * @description 删除用户
 *  @param  {Object}}userId id
 * @return 返回响应数据
 */
export function delUser(userId) {
    return request({
        url: `/api/admin/user/createUser/${userId}`,
        method: 'post',
    })
}


/**
 * @description 修改用户
 *  @param  {String}}userId id
 *  @param  {Object}}data 数据
 * @return 返回响应数据
 */
export function updateUser(userId, data) {
    return request({
        url: `/api/admin/user/update/${userId}`,
        method: 'post',
        data
    })
}