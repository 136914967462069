<!--
* @FileDescription: 侧边栏
* @Author: chenz
* @Date: 
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-aside">
    <el-menu
      :default-active="$route.path"
      router
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      background-color="#66b1ff"
      text-color="#fff"
      active-text-color="#fff"
      :collapse="isCollapse"
    >
      <!-- 一级菜单 -->
      <el-menu-item
        v-for="item in noChildren"
        :key="item.path"
        :index="item.path"
      >
        <i :class="item.icon"></i>
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
      <!-- 如果有二级菜单 -->
      <el-submenu
        v-for="(item, index) in hasChildren"
        :key="index"
        :index="item.path"
      >
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>{{ item.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(child, childIndex) in item.children"
            :key="childIndex"
            :index="child.path"
            >{{ child.title }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
// 引入侧边栏json
const asideList = require("../json/asideList")

export default {
  name: "CommonAside",
  props: {
    isCollapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 侧边栏
      asideMenuList: asideList,
    }
  },

  computed: {
    // 有二级菜单的
    hasChildren() {
      return this.asideMenuList.filter((item) => item.children)
    },

    // 没有二级菜单的
    noChildren() {
      return this.asideMenuList.filter((item) => !item.children)
    },
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },

    /**
     * @description 点击侧边栏跳转页面
     *  @param {key} path
     *  @param {keyPath} 路由路径
     */
    handleSelect(key, keyPath) {
      this.$router.push(`${keyPath}`)
    },
  },
}
</script>

<style lang="scss">
@import "../style/app.scss";

.common-aside {
  height: 100%;
}

// 侧边栏样式
.el-menu-vertical-demo {
  overflow: hidden;
}

// icon颜色
i {
  color: #fff !important;
}

.el-menu {
  height: 100%;
}

// 当前选中背景色
.el-menu-item.is-active {
  background-color: $subject-color !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
</style>
