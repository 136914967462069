<!--
* @FileDescription: 首页布局
* @Author: chenz
* @Date: 
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="home">
    <!-- home页面
     <el-button type="primary" @click="out"> 退出 </el-button> -->
    <el-container style="height: 100%">
      <el-header style="padding: 0">
        <!-- 头部 -->
        <common-header :isCollapse="isCollapse" @onFold="onFold" />
      </el-header>
      <!-- 主体 -->
      <el-container>
        <!-- 侧边栏 -->
        <el-aside
          class="transition-box"
          :style="{ width: isCollapse ? '199px' : '65px' }"
        >
          <common-aside :isCollapse="!isCollapse" />
        </el-aside>
        <!-- 内容主体 -->
        <el-main>
          <!-- 面包屑 -->
          <CommonBreadcrumb />
          <!-- transition -->
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonAside from "../../components/CommonAside"
import CommonHeader from "../../components/CommonHeader"
export default {
  components: {
    CommonAside,
    CommonHeader,
  },
  data() {
    return {
      isCollapse: true,
    }
  },
  methods: {
    out() {
      this.$router.push("/login")
    },

    // 折叠
    onFold(val) {
      this.isCollapse = val
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}

.transition-box {
  width: 200px;
  transition: width 0.5s;
}
</style>
