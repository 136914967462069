<!--
* @FileDescription: 头部
* @Author: chenz
* @Date: 文件创建时间
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-header my-bgc">
    <el-row :gutter="20">
      <!-- 折叠 -->
      <el-col :span="3">
        <i
          :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
          @click="onFold"
        ></i>
      </el-col>
      <!-- 标题 -->
      <el-col :span="16">
        <div class="title center">盲手道后台管理系统</div>
      </el-col>
      <!-- 头像及退出等 -->
      <el-col :span="5">
        <div class="avatar">
          <el-dropdown style="height: 100%; width: 100%" trigger="click">
            <el-avatar
              class="avatarBox"
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="visible = true"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item @click.native="loginOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <!-- 修改密码弹框 -->
    <el-dialog title="修改密码" :visible.sync="visible">
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="原密码" prop="originalPassword">
          <el-input
            size="small"
            v-model.trim="form.originalPassword"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            size="small"
            v-model.trim="form.newPassword"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            size="small"
            v-model.trim="form.confirmPassword"
            placeholder="再次输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="visible = false">取消</el-button>
          <el-button size="small" type="primary" @click="confirm('form')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { updatePassword } from "./../api/user"
export default {
  name: "CommonHeader",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"))
      } else {
        if (this.form.confirmPassword !== "") {
          this.$refs.form.validateField("checkPass")
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"))
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    return {
      isCollapse: true,

      // 弹框
      visible: false,

      // 修改密码的表单
      form: {
        originalPassword: "",
        newPassword: "",
        confirmPassword: "",
      },

      // 修改密码的表单校验
      rules: {
        originalPassword: [
          { required: true, message: "原密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    }
  },
  methods: {
    // 折叠面板
    onFold() {
      this.isCollapse = !this.isCollapse
      this.$emit("onFold", this.isCollapse)
    },

    confirm(form) {
      let id = window.sessionStorage.getItem("userID")
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          const res = await updatePassword({
            newPassword: this.form.newPassword,
            password: this.form.originalPassword,
            userId: id,
          })
          if (res.code !== 200) {
            return this.$message({
              type: "warning",
              message: res.message,
            })
          }
          this.$message({
            type: "success",
            message: res.message,
          })
          this.visible = false
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    // 退出
    loginOut() {
      window.sessionStorage.removeItem("token")
      window.sessionStorage.removeItem("userID")
      this.$router.push("/")
    },
  },
}
</script>

<style lang="scss">
.common-header {
  height: 100%;

  .el-row {
    width: 100%;
    height: 100%;

    .el-col {
      height: 100%;
      position: relative;
    }

    .el-col:nth-child(1) {
      position: relative;

      i {
        font-size: 28px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .title {
      width: 100%;
      height: 100%;
      line-height: 60px;
      color: #fff;
      font-size: 30px;
      text-align: center;
    }

    .avatar {
      width: 100%;
      height: 100%;
      line-height: 60px;
    }
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::v-deep .el-dropdown {
  display: inline-block;
  position: relative !important;
  color: #606266;
  font-size: 14px;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.avatarBox {
  position: absolute !important;
  right: 50px !important;
  top: 10px !important;
}

::v-deep .el-dropdown-menu .el-popper {
  top: 48px !important;
}
</style>
